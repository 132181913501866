import {http} from '../configs/http';

/**
 * 创建series
 * @param {Object}  data series对象
 * @param {string} description    描述
 * @param {string} image    图片
 * @param {string} lockUp    交易锁定天数
 * @param {string} name    名字
 * @param {string}propertyValue    房地产价值 单位:分
 * @param {string} reInvestmentFundNo
 * @param {string}seriesLlcRegNo    这个系列注册的公司号
 * @param {string} stage    Claim 或者 Genetic
 * @param {string} subscriptionBookletNo    http地址
 * @param {string} symbol    symbol
 * @param {string} totalPieces    总片数
 * @param {Array} traitList
 * @param {Array.Object.string} Trait
 * @param {Array.Object.string} traitType
 * @param {string} type    类型
 * @param {string} video    视频
 */
export const query_create_seriest = (data) => {
    return http.post('/manage/pnft/create_series', {...data})
}

/**
 * series列表
 * @param page
 * @param size
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_series_list = (page, size = 10) => {
    return http.post('/manage/pnft/series_list', {page, size})
}
/**
 * 获取series详情
 * @param seriesId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_series_detail = (seriesId) => {
    return http.post('/manage/pnft/detail', {seriesId})
}
/**
 * 编辑series
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_edit_series = (data) => {
    return http.post('/manage/pnft/edit_series', {...data})
}
/**
 * 删除series
 * @param seriesId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_remove_series = (seriesId) => {
    return http.post('/manage/pnft/remove_series', {seriesId})
}
/**
 * 获取series的pnft列表
 * @param seriesId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_get_by_series = (seriesId) => {
    return http.post('/manage/pnft/pnft/get_by_series', {seriesId})
}
/**
 * 获取series的tags标签
 * @param seriesId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_get_tags = (seriesId) => {
    return http.post('/manage/pnft/pnft/tags', {seriesId})
}


/**
 * 创建tier
 * @param data
 * @param {number} basePrice    成本价格
 * @param {string} image    nft图片
 * @param {number} issue    发行数量
 * @param {string} name    名字
 * @param {number} sellPrice    销售价格 单位美分
 * @param {string} seriesId    所属seriesId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_tier_create = (data) => {
    return http.post('/manage/pnft/tier/create', {...data})
}
/**
 * 删除tier
 * @param tierId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_tier_remove = (tierId) => {
    return http.post('/manage/pnft/tier/remove', {tierId})
}
/**
 * tier列表
 * @param seriesId 系列ID
 * @param page 页码
 * @param size  页数
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_tier_list = (seriesId, page, size = 10) => {
    return http.post('/manage/pnft/tier/list', {seriesId, page, size})
}
/**
 * 创建pnft
 * @param {Array} list
 * @param {String} tierId
 * @param {Array.Object.String} piece
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_create_pnft = (list, tierId) => {
    return http.post('/manage/pnft/pnft/create', {list, tierId})
}
/**
 * 编辑pnft
 * @param {Array} list
 * @param {String} tierId
 * @param {Array.Object.String} piece
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const query_edit_pnft = (list, tierId) => {
    return http.post('/manage/pnft/pnft/edit', {list, tierId})
}
/**
 * 获取tier下nft列表
 * @param tierId
 * @returns
 */
export const query_get_by_tier = (tierId) => {
    return http.post('/manage/pnft/pnft/get_by_tier', {tierId})
}

/**
 * 获取pnft.faas列表
 * @param seriesId
 * @param state 状态 faas=上链,imminent= coming soon
 * @returns {Promise<axios.AxiosResponse<{
 *  failStage: string,
 *  state: string,
 *  txId: string
 * }>>}
 * {
 *  state 状态  SUCCESS , FAIL 当状态为FAIL时才有failStage
 *  failStage : 失败阶段 DOWNLOAD_IMAGE 下载图片失败 GENERATE_METADATA 生成元数据失败 ,UPLOAD_TO_IPFS 上传至ipfs失败
 * }
 */
export const query_pnft_faas = (seriesId, state) => {
    return http.post('/manage/pnft/pnft/blockChainSeries', {seriesId, state})
}

/**
 * 查询faas上链状态（轮询）
 * @param {string} txId 事务id
 * @returns
 */
export const query_pnft_faas_chain_status = (txId) => {
    return http.post('/manage/pnft/pnft/query_slot_create_result', {txId})
}

