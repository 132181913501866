import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { http } from './configs/http'
import antDesign from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { message } from 'ant-design-vue'
import VueClipboard from 'vue-clipboard2'
import { setHtmlFontSize } from './libs/jsm/reset'
import directives from './utils/directive'
import "./configs/NumberUtility"
import "./font/font.css"
import "./styles/tailwind.css"

Vue.use(directives)
Vue.use(antDesign)
Vue.prototype.$http = http
Vue.config.productionTip = false

Vue.prototype.$message = message
Vue.prototype.$message.config({
  top: `300rem`,
  duration: 3,
  maxCount: 1
})

Vue.prototype.$checkLogin = () => {
  const token = window.localStorage.getItem('token') || ''
  return token == '' ? false : true
}

Vue.prototype.$getUserInfo = () => {
  store.dispatch('getUserInfo')
}
store.dispatch('getUserInfo')
store.dispatch('checkRedPoint')

Vue.prototype.$bus = new Vue()
Vue.use(VueClipboard)

window.onresize = setHtmlFontSize
setHtmlFontSize()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
