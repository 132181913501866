import Vue from 'vue'
import Vuex from 'vuex'
import { checkREDivRP, checkREDTransfer } from '../libs/api'
import pnft from './pnft'

Vue.use(Vuex)
const EXPIRE_TIME = 1000 * 60 * 30;
export default new Vuex.Store({
    state: {
        REDividend_isRed: null,
        Transfer_isRed: null,
        urlCache: {},
        fileCache: {}
    },
    mutations: {
        changeREDiv(state, val) {
            state.REDividend_isRed = val
        },
        changeTransfer(state, val) {
            state.Transfer_isRed = val
        },
        setUrlCache(state, { path, url }) {
            state.urlCache[path] = url;
        },
        getFileCache(state, { key, callback }) {
            const cache = state.fileCache[key];
            if (cache && cache.expire > Date.now()) {
                return callback(cache.url);
            }
            callback(null);
        },
        setFileCache(state, { key, value }) {
            state.fileCache[key] = {
                url: value,
                expire: Date.now() + EXPIRE_TIME
            };
        }
    },
    actions: {
        async getUserInfo() { },
        async checkRedPoint(context) {
            let token = localStorage.getItem('token')
            let catalog = localStorage.getItem('catalog')
            if (!token || (catalog != 5 && catalog != 6)) return
            const { exist } = await checkREDivRP()
            const res = await checkREDTransfer()
            context.commit('changeREDiv', exist)
            context.commit('changeTransfer', res.exist)
        }
    },
    modules: { pnft }
})
