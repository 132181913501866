import {http} from '../../configs/http';
import {rsaEncrypt} from '@/utils/tools.js';
import store from "../../store/index"
/*-----------------------文件上传+下载----------------------------*/
//#region
/**
 * 文件上传
 * @param {file} file 文件
 * @param {string} fileName 文件名
 */
export const uploadFile = (file, fileName) => {
    const path = Math.floor(Date.now()) + '_' + fileName;
    return http.post('/manage/file/upload', {file, path}, {
        headers: {"Content-Type": "multipart/form-data"}
    });
};

/**
 * 文件下载
 * @param {string} path 文件路径
 */
export const downloadFile = async path => {
    return new Promise(resolve => {
        store.commit('getFileCache', {
            key: path,
            callback: async (cache) => {
                if (!cache) {
                    const { url } = await http.post('/manage/file/download', { path });
                    store.commit('setFileCache', {key:path, value:url});
                    return resolve({ url });
                }
                resolve({ url: cache });
            }
        });
    })
};
//#endregion

/*-----------------------登录+用户相关----------------------------*/
//#region
/**
 * 登录
 * @param {string} account 账号
 * @param {string} pwd 密码
 * @returns {object}
 */
export const login = (account, pwd) => {
    const psw = rsaEncrypt(pwd);
    return http.post('/manage/account/login', {account, psw});
};

/**
 * 获取用户列表
 * @param {object} params 
 * @param {string} params.userID 根据用户userID或名字进行检索
 * @param {string} params.username 
 * @param {string} params.email
 * @param {boolean} params.status
 * @param {number} params.regDateFrom
 * @param {number} params.regDateTo
 * @param {number} params.regDateTo
 * @param {number} params.regDateTo
 * @param {string} params.source
 * @param {string} params.country
 * @param {number} params.shardStart
 * @param {number} params.shardEnd
 * @param {number} params.order 0-正序 1-倒序
 * @param {number} params.page
 * @param {number} params.size
 */
export const getUserList = (params) => {
    return http.post('/manage/user/list', params);
};
/**
 * 修改用户登录状态
 * @param {string} id USER_ID
 * @param {string} loginStatus  是否允许登录，0:不允许，1:允许
 * @param {string} purchaseStatus 是否禁止用户购买 0:禁止 1:不禁止
 * @param {string} sellingStatus  是否禁止禁止用户销售 0:禁止 1:不禁止
 * @param {string} withdrawStatus 是否禁止用户提现 0:禁止 1:不禁止
 * @returns {boolean}
 */
export const editLoginStatus = (
    id,
    loginStatus,
    purchaseStatus,
    sellingStatus,
    withdrawStatus
) => {
    return http.post('/manage/user/edit_login_status', {
        id,
        loginStatus,
        purchaseStatus,
        sellingStatus,
        withdrawStatus
    });
};
/**
 * 用户详情
 * @param {string} userId
 */
export const getUserInfoDetail = userId => {
    return http.post('/manage/user/user_info', {userId});
};
/**
 * 用户藏品
 * @param {string} userId
 * @param {string} page
 * @param {string} size
 */
export const getUserCollected = (userId, page, size) => {
    return http.post('/manage/user/user_collected', {userId, page, size});
};
/**
 * 用户登录日志
 * @param {string} userId
 */
export const getUserLoginLog = userId => {
    return http.post('/manage/user/login_log', {userId});
};

//#endregion

/*-----------------------coupon相关----------------------------*/
// #region
/**
 * 获取所有符合条件的coupon
 * @param {string} keyword 关键词
 * @returns
 */
export const getAllCounponList = keyword => {
    return new Promise(res => {
        http.post('/manage/coupon/all', {keyword}).then(data => {
            res(data);
        });
    });
};
//#endregion

/*-----------------------提现相关----------------------------*/
// #region
/**
 * 获取汇总列表
 * @param {number} page 页码
 * @param {number} type 1:pass 2:reject 3partial -1:ALL
 * @param {string} transId 查询的withdraw id
 * @param {string} beginTime 开始时间 秒级unix
 * @param {string} endTime 结束时间 秒级unix
 * @returns
 */
export const requireWithdrawList = (
    page,
    type = -1,
    transId = '',
    beginTime = null,
    endTime = null
) => {
    return http.post('/manage/withdraw/summary_list', {
        page,
        size: 10,
        beginTime,
        endTime,
        transId,
        type
    });
};

/**
 * 手动汇总(财务)
 * @returns {promise} {num,success}
 */
export const requireSummaryManual = () => {
    return http.post('/manage/withdraw/summary_manual', {});
};

/**
 * 获取提现汇总详情
 * @param {number} page 页码
 * @param {string} summaryId 提现详情汇总id
 * @returns
 */
export const requireWithdrawDetail = (page, summaryId) => {
    return http.post('/manage/withdraw/summary_detail', {
        page,
        summaryId,
        size: 10
    });
};

/**
 * 财务审核提现汇总数据
 * @param {array} params 要审核的数据数组
 * @param {string} summaryId 汇总数据的id
 * @returns {promise} {success}
 */
export const requireTreasurerAudit = (params, summaryId) => {
    return http.post('/manage/withdraw/finance_audit', {params, summaryId});
};

/**
 * 审核全部 pass / reject
 * @param {string} reason 原因
 * @param {number} rejectType 拒绝类型 1:KYC/AML 2:RulerBreak 3:AccountProblem 4:others
 * @param {number} status 1=pass 2=reject
 * @param {string} summaryId 汇总id
 * @param {boolean} suspendWithdraw 是否停用 true:停用
 * @returns {promise} {success}
 */
export const requireTreasurerAuditAll = (
    reason,
    rejectType,
    status,
    summaryId,
    suspendWithdraw
) => {
    return http.post('/manage/withdraw/finance_audit_batch', {
        reason,
        rejectType,
        status,
        summaryId,
        suspendWithdraw
    });
};

/**
 * 获取用户提现的基本信息
 * @param {string} userId 用户id
 * @returns
 */
export const requireWithdrawUserInfo = userId => {
    return http.post('/manage/withdraw/getWithdrawUser', {userId});
};

/**
 * 获取提现操作记录历史
 * @param {string} summaryId 汇总id
 * @returns
 */
export const requireOperatingRecord = summaryId => {
    return http.post('/manage/withdraw/summary_history', {summaryId});
};

/**
 * 获取管理员获取审核列表
 * @param {number} beginTime
 * @param {number} endTime
 * @param {number} page
 * @param {number} size
 * @param {string} transId 提醒请求id
 * @param {number} type 类型 1:pending 2done
 * @returns
 */
export const getManageAuditList = (
    beginTime,
    endTime,
    page,
    size,
    transId,
    type
) => {
    return http.post('/manage/withdraw/manage_audit_list', {
        beginTime,
        endTime,
        page,
        size,
        transId,
        type
    });
};
/**
 * 获取on hold金额
 */
export const getOnHoldAmount = () => {
    return http.post('/manage/withdraw/manage_on_hold_amount', {});
};

// ···················钱包部分·····················

/**
 * 获取链接钱包的签名数据(第一次签署)
 * @param {string} summaryId 汇总id
 * @returns {object} {amount:金额, contractAddress:代币合约地址, nonce:唯一值, toAddress:接受者地址}
 */
export const requireWelletSignData = summaryId => {
    return http.post('/manage/withdraw/getWithDrawSignData', {summaryId});
};

/**
 * 一签保存签名后数据和源数据
 * @param {string} signData 签名数据
 * @param {string} signatures 签名
 * @param {string} summaryId 汇总id
 * @returns
 */
export const saveSignaturesAndSourceData = (
    signData,
    signatures,
    summaryId
) => {
    return http.post('/manage/withdraw/saveWithDrawSignatures', {
        signData,
        signatures,
        summaryId
    });
};

/**
 * 获取签署签名数据(第二次签署)
 * @param {string} summaryId
 * @returns  {object} {amount:金额, contractAddress:代币合约地址, nonce:唯一值, signData:签名源数据, signatures:签名后数据}
 */
export const requireWelletSignaturesData = summaryId => {
    return http.post('/manage/withdraw/getWithDrawSignatures', {summaryId});
};

/**
 * 拒绝审批
 * @param {string} summaryId
 * @returns
 */
export const rejectApprove = summaryId => {
    return http.post('/manage/withdraw/manage_reject_audit', {summaryId});
};

/**
 * 二次签署确认之后通知后台
 * @param {string} signData 签名数据源
 * @param {string} signatures 签名
 * @param {string} summaryId 汇总id
 * @returns
 */
export const confirmSecondSign = (signData, signatures, summaryId) => {
    return http.post('/manage/withdraw/confirm_second_sign', {
        signData,
        signatures,
        summaryId
    });
};
// #endregion

/*-----------------------头像框相关----------------------------*/
//#region
/**
 * 头像框图片列表
 * @param {string} key
 * @param {number} page
 * @param {number} size
 */
export const getFrameList = (key, page, size) => {
    return http.post('/manage/frame/list', {key, page, size});
};

/**
 * 头像框更改状态
 * @param {string} frameId
 * @param {number} status 0未启用 1启用
 * @returns "success": true
 */
export const changeFrameUseStatus = (frameId, status) => {
    return http.post('/manage/frame/change_use_status', {frameId, status});
};

/**
 * 更改图片
 * @param {string} frameId
 * @param {string} roundedCorners
 * @param {string} squareCorners
 */
export const changeFramePic = (frameId, roundedCorners, squareCorners) => {
    return http.post('/manage/frame/change_pic', {
        frameId,
        roundedCorners,
        squareCorners
    });
};
/**
 * 上传头像框
 * @param {string} description
 * @param {string} name
 * @param {string} roundedCorners
 * @param {string} squareCorners
 */
export const saveFrame = (name, description, roundedCorners, squareCorners) => {
    return http.post('/manage/frame/save', {
        description,
        name,
        roundedCorners,
        squareCorners
    });
};
//#endregion

/*-----------------------Airdrop相关----------------------------*/
//#region
/**
 * 空投Airdrop
 * @param {string} code 验证码
 * @param {number} type 1=brick 2=item 3=pnft
 * @param {array} list [amount,email]
 */
export const sendAirdrop = (code, type, list) => {
    return http.post('/manage/airdrop/send', {code, type, list});
};

/**
 * airdrop发送验证码
 */
export const getAirdropCode = () => {
    return http.post('/manage/airdrop/send_code', {});
};

/**
 * 获取airdrop列表
 * @param {string} beginTime
 * @param {string} endTime
 * @param {string} operatorKey 操作人关键
 * @param {string} page
 * @param {string} recipientKey 接收人关键词
 * @param {string} size
 */
export const getAirdropList = (
    beginTime,
    endTime,
    operatorKey,
    recipientKey,
    page,
    size
) => {
    return http.post('/manage/airdrop/list', {
        beginTime,
        endTime,
        operatorKey,
        recipientKey,
        page,
        size
    });
};

/**
 * 获取airdrop详情
 * @param {string} airdropId  空投id
 * @param {string} page
 * @param {string} size
 */
export const getAirdropDetail = (airdropId, page, size) => {
    return http.post('/manage/airdrop/detail', {airdropId, page, size});
};
//#endregion

/*-----------------------网红管理相关----------------------------*/
// #region

/**
 * 获取网红列表
 * @param {string} key keyword
 * @param {string} page 分页
 * @param {string} size 每页条数
 * @returns
 */
export const query_celebrity_list = (key, page, size = 10) => {
    return http.post('/manage/celebrity/list', {key, page, size});
};

/**
 * 添加网红
 * @param {string} code 网红专属码
 * @param {string} name 名字
 * @param {string} platform 平台
 * @returns
 */
export const create_celebrity = (code, name, platform) => {
    return http.post('/manage/celebrity/save', {code, name, platform});
};

/**
 * 删除网红
 * @param {string} id 网红id
 * @returns
 */
export const delete_celebrity = id => {
    return http.post('/manage/celebrity/del', {id});
};

/**
 * 查看网红邀请信息
 * @param {string} inviteCode 网红专属code
 * @returns
 */
export const query_celebrity_info = inviteCode => {
    return http.post('/manage/celebrity/info', {inviteCode});
};

/**
 * 设置网红状态
 * @param {string} id 网红主键id
 * @param {number} status 状态 0=启用 1=禁用
 * @returns
 */
export const set_celebrity_status = (id, status) => {
    return http.post('/manage/celebrity/status', {id, status});
};
//#endregion

/*-----------------------CaaS相关----------------------------*/
//#region

/** 运营方列表
 * @param {string} page
 * @param {string} size
 */
export const gameList = (page, size) => {
    return http.post('/manage/game/list', {page, size});
};

/** 运营方基本信息
 * @param {string} gameOperatorId
 */
export const gameBaseInfo = gameOperatorId => {
    return http.post('/manage/game/base_info', {gameOperatorId});
};

/** 运营方dashboard
 * @param {string} gameOperatorId 游戏公司id
 */
export const gameDashboard = gameOperatorId => {
    return http.post('/manage/game/dashboard', {
        gameOperatorId
    });
};

/** 运营方dashboard
 * @param {string} gameOperatorId 游戏公司id
 * @param {string} beginTime 开始时间戳 秒级时间戳
 */
export const gameMonthly_sale = (gameOperatorId, beginTime) => {
    return http.post('/manage/game/monthly_sale', {
        gameOperatorId, beginTime
    });
};

/** 运营方items
 * @param {string} gameOperatorId 游戏公司id
 * @param page
 * @param size
 */
export const gameItem = (gameOperatorId, page, size) => {
    return http.post('/manage/game/item', {
        gameOperatorId,
        page,
        size
    });
};
/** 运营方items 道具map 映射
 * @param {string} gameItemName 游戏道具名
 * @param {string} gameOperatorId 游戏公司id
 * @param page
 * @param size
 */
export const game_item_map = (gameItemName, gameOperatorId, page, size) => {
    return http.post('/manage/game/item_map', {
        gameItemName,
        gameOperatorId,
        page,
        size
    });
};

/** 运营方activatePayment
 * @param {string} beginTime 开始时间 秒级时间戳
 * @param {string} gameOperatorId 游戏公司id
 * @param page
 * @param size
 */
export const gameActivatePayment = (gameOperatorId, page, size, beginTime) => {
    return http.post('/manage/game/activatePayment', {
        gameOperatorId,
        page,
        size,
        beginTime
    });
};

/** 运营方trade in 记录
 * @param {string} beginTime 开始时间 秒级时间戳
 * @param {string} operatorId 游戏公司id
 * @param page
 * @param size
 */
export const gameTrade_in = (operatorId, page, size, beginTime) => {
    return http.post('/manage/game/trade_in', {
        beginTime,
        operatorId,
        page,
        size
    });
};

/** 添加游戏运营方
 * @param {string} account
 * @param {string} address
 * @param {string} contact
 * @param {string} email
 * @param {string} logo
 * @param {string} name
 * @param {string} password 密码 base64编码(rsa(真实密码))
 * @param {string} website
 */
export const gameAdd = res => {
    const psw = rsaEncrypt(res.password);
    return http.post('/manage/game/add', {
        account: res.account,
        address: res.address,
        contact: res.contact,
        email: res.email,
        logo: res.logo,
        name: res.name,
        password: psw,
        website: res.website
    });
};
//--------审核audit--------
/**
 * Audit审核
 * @param {array} gameBaseId    道具列表id
 * @param {string} rejectReason 拒绝原因
 * @param {number} status 状态 1=接受 2=拒绝
 */
export const game_item_audit_audit = (gameBaseId, rejectReason, status) => {
    return http.post('/manage/game_item_audit/audit', {
        gameBaseId,
        rejectReason,
        status
    });
};

/**
 * Audit审核列表
 * @param {number} page
 * @param {number} size
 * @param {number} status 状态 0=未审核 1=已审核
 * @param {string} reviewer 操作人
 */
export const game_item_audit_list = (page, size, status, reviewer) => {
    return http.post('/manage/game_item_audit/list', {
        page,
        size,
        status,
        reviewer
    });
};

/**
 * Audit审核详情
 * @param {string} baseId    游戏道具列表id
 */
export const game_item_audit_detail = baseId => {
    return http.post('/manage/game_item_audit/detail', {baseId});
}
//#endregion

/*-----------------------fomosale相关----------------------------*/
//#region
/**
 * fomosale列表
 * @param {number} page
 * @param {number} size
 */
export const getSaleList = (page, size) => {
    return http.post("/manage/promotion/list", {page, size})
}
/**
 * fomosale开启促销活动
 * @param {string} promotionId
 * @param {number} status 0停用 1启用
 * @returns "success": true
 */
export const changeSaleStatus = (promotionId, status) => {
    return http.post("/manage/promotion/start", {promotionId, status})
}
/**
 * fomosale删除促销
 * @param {string} promotionId
 * @returns "success": true
 */
export const delSale = (promotionId) => {
    return http.post("/manage/promotion/delete", {promotionId})
}

/**
 * 保存/编辑优惠表单
 * @param  allowCoupon 是否允许与coupon叠加 0=不允许 1=允许
 * @param  allowMember 是否允许与membership优惠叠加 0=不允许 1=允许
 * @param  announceHour 预告开启前多少小时可视
 * @param  announceType 预告类型 0=立即可视 1=活动开启前x小时可视 2=不做预告
 * @param  endTime 结束时间
 * @param  limitAmount 限购数量
 * @param  limitType 限购设置类型 -1=不限制 0=限购x个后不能购买 1=限购x个后恢复原价
 * @param  promotionNftList: [ 参加活动的nft
 {
 discount: "", 优惠 百分比的话为0.x模式 例如80% off =0.8 立减和直接价位为美分
 discountType: 0, 优惠类型 0=打折 1=立减 2=直接设置价格
 nftId: "", nft id
 selling: 0, 参与活动的数量 -1=不限制
 },
 ],
 * @param  scope 使用人群限制 1=normal 2=investor 3=mogul
 * @param  startTime 开始时间
 * @param  title 活动名
 */
export const saveFomoSaleForm = (obj, type = 1) => {
    let link = ''
    if (type == 1) {
        link = '/manage/promotion/save'
    } else {
        link = '/manage/promotion/edit'
    }
    return http.post(link, {
        allowCoupon: obj.allowCoupon,
        allowMember: obj.allowMember,
        announceHour: obj.announceHour,
        announceType: obj.announceType,
        endTime: obj.endTime,
        limitAmount: obj.limitAmount,
        limitType: obj.limitType,
        promotionNftList: obj.promotionNftList,
        scope: obj.scope,
        startTime: obj.startTime,
        title: obj.title,
        id: obj.id || '',
    })
}

/**
 * 促销详情
 * @param {string} promotionId
 */
export const getFomosaleBackFillData = (promotionId) => {
    return http.post("/manage/promotion/detail", {promotionId})
}

/**
 * 根据nft名获取nft信息
 * @param {string} key
 */
export const getNFTSearchList = (key) => {
    return http.post("/manage/promotion/findNft", {key})
}

/**
 * report
 * @param {string} page
 * @param {string} promotionId
 * @param {string} size
 *
 */
export const getReport = (promotionId, page, size) => {
    return http.post("/manage/promotion/report", {promotionId, page, size})
}
//#endregion
