import { downloadFile } from "../libs/http/api";
import default_avatar from "../assets/defaultAvatar.png"
export default {
    install(Vue, _options) {
        Vue.directive('imgError', {
            inserted(el, obj) {
                // js自带出错事件, 会一直监听el,直至el销毁 所以下面的componUpdated不用再写
                el.style.width = '100%';
                el.style.height = '100%';
                el.onerror = () => {
                    el.parentNode.innerHTML = `<video loop="loop" style="width:100%;height:100%" autoplay="autoplay" muted="muted" src="${el.src}"></video>`;
                };
            }
        });
        Vue.directive('path2url', {
            inserted(el, binding) {
                // js自带出错事件, 会一直监听el,直至el销毁 所以下面的componUpdated不用再写
                getUrlByPath(el, binding)
                
            }
        });
        Vue.directive('opacityEnter', {
            inserted(el) {
                el.style.opacity = 0;
                el.style.height = 0;
                el.style.transform = "scaleY(0)";
                el.style.transformOrigin = "0 0";
                el.style.transition = "all 1s ease";
                const T = setTimeout(() => {
                    clearTimeout(T);
                    el.style.opacity = 1;
                    el.style.height = 'auto';
                    el.style.transform = "scaleY(1)";
                }, 50);
                
            }
        });
    }
};
async function getUrlByPath(el, binding) {
    const animation = el.animate([
        {
            opacity: '0'
        },
        {
            opacity: '1'
        }
    ], {
        duration: 500,
        easing: 'ease',
        fill: 'forwards'
    });

    animation.pause();
    if (!binding.value) {
        el.src = default_avatar;
    } else {
        let value = '';
        if (typeof binding.value === 'object') {
            value = binding.value.value;
            let el_type = binding.value.type || '';
            if (el_type === 'avatar' && !value) return el.src = default_avatar;
        } else {
            value = binding.value
        }
        if (!value) return el.src = "";
        const reg_result = value?.indexOf("https") == 0;
        if (reg_result) {
            el.src = value.replace('https://d30wsnvmtingz5.cloudfront.net', 'https://cdn.playestates.com');
        } else {
            const { url } = await downloadFile(value);
            el.src = url;
        }
    }

    if (el.tagName === 'VIDEO') {
        el.addEventListener('canplaythrough', () => {
            animation.play();
        }, { once: true });
    } else {
        el.onload = () => animation.play();
        el.onerror = () => el.src = default_avatar;
    }
}