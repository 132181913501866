import {query_series_detail} from "@/api/pnft-fragment";

export default {
    state: () => ({
        createSeries: {},
        seriesId: ''
    }),
    mutations: {
        setSeries(state, data) {
            state.createSeries = {...state.createSeries, ...data}
        },
        initSeries(state) {
            state.createSeries = {}
        },
        setSeriesId(state, data) {
            state.seriesId = data
        },
    },
    actions: {
        async getSeriesData({commit, state}) {
            if (state.seriesId) {
                let {seriesVo} = await query_series_detail(state.seriesId)
                commit('setSeries', seriesVo)
            }
        }
    },
    getters: {}
}
