import Axios from "axios"
import { message } from "ant-design-vue"
let isExpired = false;
export const http = Axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 1000 * 60 * 30,
    headers: {
        'Content-Type': 'application/json'
    },
})

// 请求拦截
http.interceptors.request.use(
    config => {
        const Authorization = getToken()
        if (config.url == "/manage/account/login") isExpired = false;
        if (config.method == 'post') {
            if (Authorization) {
                config.headers = {
                    ...config.headers,
                    Authorization
                }
            }
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
// 响应拦截
http.interceptors.response.use(
    res => {
        const { data } = res
        if (data.errorCode == 1) {
            if (!isExpired) {
                isExpired = true;
                alert("Account expired, please log in again.")
            }
            clearCatch()
            window.location.href = "/#/login";
            return
        } else {
            if (data.errorCode == 0) return data.data
            message.error(data.errorMsg)
            return Promise.reject(data.errorMsg)
        }
    },
    err => {
        return Promise.reject(err)
    }
)

function getToken() {
    return window.localStorage.getItem("token") || ''

}

function clearCatch() {
    window.localStorage.clear()
}