import { http } from '../configs/http'


/** 检查是否已经设置分红 红点
 */
export const checkREDivRP = () => {
  return http.post('/manage/dividend/getHint', {})
}
/**
 * 检查管理员审核 红点
 */
export const checkREDTransfer = () => {
  return http.post('/manage/withdraw/manage_audit_hint', {})
}
